enum FilterActions {
    ACC_ID_CHANGE = 'ACCOUNTID',
    CAT_CHANGE = 'CATEGORY',
    DATE_CHANGE = 'DATE',
    DOC_NAME_CHANGE = 'DOCNAME',
    PLAN_DESC_CHANGE = 'PLANDESCRIPTION',
    PLAN_ID_CHANGE = 'PLANID',
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    handler: any,
    pageUpdate: any,
    pagination: paginationObjectType,
    filterValue: string,
}

const DateDropdown = (props: propType) => {

    const change = (selectedOption: string) => {
        props.handler({ type: FilterActions.DATE_CHANGE, filterData: { date: getDate(selectedOption), filter: selectedOption } })
        props.pageUpdate({itemsPerPage: props.pagination.itemsPerPage, currPage: 0});
    }

    const getDate = (option: string) => {
        switch (option) {
            case 'All':
                //Note: 100 years should be good enough for "all" right?
                return new Date(new Date().setFullYear(new Date().getFullYear() - 100));
            case 'Last 90 days':
                return new Date(new Date().setDate(new Date().getDate() - 90));
            case 'Last 6 months':
                return new Date(new Date().setMonth(new Date().getMonth() - 6));
            case 'Last year':
                return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
            case 'Last 18 months':
                return new Date(new Date().setMonth(new Date().getMonth() - 18));
            case 'Last 2 years':
                return new Date(new Date().setFullYear(new Date().getFullYear() - 2));
            default:
                return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        }
    };

    return (
        <div className="filter-dropdown">
            <label className="pds-label">Date range</label>
            <div className="pds-select">
                <select value={props.filterValue} name="select-input" data-testid='test-doc-date-filter' onChange={(e) => change(e.target.value)}>
                    <option value='All'>All</option>
                    <option value={'Last 90 days'}>Last 90 days</option>
                    <option value={'Last 6 months'}>Last 6 months</option>
                    <option value={'Last year'} >Last year</option>
                    <option value={'Last 18 months'}>Last 18 months</option>
                    <option value={'Last 2 years'}>Last 2 years</option>
                </select>
            </div>
        </div>
    )
}

export default DateDropdown;