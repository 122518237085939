
export const enum HEADER_PRODUCT_URLS {
    ManageProducts_URL_TEST = 'https://secure05.test.principal.com/employers/corp/Summary/ERSummary',
    ManageProducts_URL_PILOT = 'https://secure05.pilot.principal.com/employers/corp/Summary/ERSummary',
    ManageProducts_URL_PROD = 'https://secure05.principal.com/employers/corp/Summary/ERSummary',

    ManageSecurity_URL_TEST = 'https://secure05.test.principal.com/employers/corp/security/ManagedSecurityServlet',
    ManageSecurity_URL_PILOT = 'https://secure05.pilot.principal.com/employers/corp/security/ManagedSecurityServlet',
    ManageSecurity_URL_PROD = 'https://secure05.principal.com/employers/corp/security/ManagedSecurityServlet',

    BillingApp_URL_TEST = 'https://billing.employers.dev.principal.com',
    BillingApp_URL_PILOT = 'https://billing.employers.staging.principal.com',
    BillingApp_URL_PROD = 'https://billing.employers.principal.com',

    MyProfile_URL_TEST = 'https://secure05.test.principal.com/yourprofile/isea/employer/yourprofile.jsp',
    MyProfile_URL_PILOT = 'https://secure05.pilot.principal.com/yourprofile/isea/employer/yourprofile.jsp',
    MyProfile_URL_PROD = 'https://secure05.principal.com/yourprofile/isea/employer/yourprofile.jsp',

    MessageCenter_URL_TEST = 'https://secure02.test.principal.com/servlet/common/employer/messagecenter',
    MessageCenter_URL_PILOT = 'https://secure02.pilot.principal.com/servlet/common/employer/messagecenter',
    MessageCenter_URL_PROD = 'https://secure02.principal.com/servlet/common/employer/messagecenter',

    LogOut_URL_TEST = 'https://secure05.test.principal.com/auth/corp/logout.jsp',
    LogOut_URL_PILOT = 'https://secure05.pilot.principal.com/auth/corp/logout.jsp',
    LogOut_URL_PROD = 'https://secure05.principal.com/auth/corp/logout.jsp',

}
export const enum BFF_API_URLS {
    BFF_API_URL_DEV = 'https://api.header.employerexperience.dev.principal.com/',
    BFF_API_URL_STAGING = 'https://api.header.employerexperience.staging.principal.com/',
    BFF_API_URL_PROD = 'https://api.header.employerexperience.principal.com/',

}
