import { useEffect, useReducer, useState } from "react";
import { useAuth } from "react-oidc-context";
import AccountIdDropdown from "./AccountIdDropdown";
import DateDropdown from "./DateDropdown";
import DocNameDropdown from "./DocNameDropdown";
import PaginationDropdown from "./PaginationDropdown";
import PaginationNavigation from "./PaginationNavigation";
import PlanDescriptionDropdown from "./PlanDescriptionDropdown";
import PlanIdDropdown from "./PlanIdDropdown";


type Document = {
    accountId: string,
    businessSegment: string,
    displayCategory: string,
    displayName: string,
    documentCategoryType: string,
    documentTimestamp: string,
    fullPlanId: string,
    uiDisplayViewed: boolean,
    userDefinedProperties: [],
    links: {
        pdf: string
    },
}

type FilterLists = {
    accountIds: string[],
    displayNames: string[],
    displayCategories: string[],
    fullPlanIds: string[],
}

type DateFilter = {
    date: Date,
    filter: string,
}

const DEFAULT_FILTER = 'All';
const DEFAULT_FILTER_STATES: FilterOptions = {
    docNameFilter: DEFAULT_FILTER,
    planDescFilter: DEFAULT_FILTER,
    planIdFilter: DEFAULT_FILTER,
    accountIdFilter: DEFAULT_FILTER,
    dateFilter: { date: new Date(new Date().setFullYear(new Date().getFullYear() - 1)), filter: 'Last year' },
}

enum FilterActions {
    ACC_ID_CHANGE = 'ACCOUNTID',
    DATE_CHANGE = 'DATE',
    DOC_NAME_CHANGE = 'DOCNAME',
    PLAN_DESC_CHANGE = 'PLANDESCRIPTION',
    PLAN_ID_CHANGE = 'PLANID',
}

type FilterOptions = {
    docNameFilter: string,
    planDescFilter: string,
    planIdFilter: string,
    accountIdFilter: string,
    dateFilter: DateFilter,
}

type propType = {
    documents: Document[]
    filterLists: FilterLists
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

const filterReducer = (previous: FilterOptions, action: { type: FilterActions, filterData: any }) => {
    switch (action.type) {
        case FilterActions.DATE_CHANGE:
            return { ...previous, dateFilter: action.filterData };
        case FilterActions.ACC_ID_CHANGE:
            return { ...previous, accountIdFilter: action.filterData };
        case FilterActions.PLAN_DESC_CHANGE:
            return { ...previous, planDescFilter: action.filterData };
        case FilterActions.PLAN_ID_CHANGE:
            return { ...previous, planIdFilter: action.filterData };
        case FilterActions.DOC_NAME_CHANGE:
            return { ...previous, docNameFilter: action.filterData };
        default:
            return previous
    }
};


const EmployerDocTable = (props: propType) => {

    props.documents.sort((a, b) => (((a as any)['documentTimestamp'] == null ? "" : (a as any)['documentTimestamp'].toString().toLowerCase())
        > ((b as any)['documentTimestamp'] == null ? "" : (b as any)['documentTimestamp'].toString().toLowerCase()) ? -1 : 1));

    const [filters, updateFilters] = useReducer(filterReducer, DEFAULT_FILTER_STATES);
    const [planDescFilterOptions, updatePlanDescFilterOptions] = useState<string[]>([]);
    const [documentsState, setDocumentsState] = useState<Document[]>(props.documents);
    const [filteredAndSortedDocState, setFilteredAndSortedDocState] = useState<Document[]>(props.documents);
    const [sortStates, setSortStates] = useState<string[]>(['DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT']);
    const [paginationVals, setPaginationVals] = useState<paginationObjectType>({ itemsPerPage: 10, currPage: 0 });
    const [numEntries, setNumEntries] = useState<number>(0);

    // Setup planDescFilter due to it being a UDF meaning it needs json parsing logic + etc
    useEffect(() => {
        let descOptions = props.documents
            .map((document) => {
                let planDescUDF = (document.userDefinedProperties.filter((properties) => properties['label'] === 'planDescription')).map((property) => property['text']);
                return planDescUDF.length > 0 ? planDescUDF[0] : '';
            });

        descOptions.unshift('All');

        let descFilterSet = new Set(descOptions);
        descOptions = Array.from(descFilterSet);

        updatePlanDescFilterOptions(Array.from(descFilterSet));
    }, [props.documents]);

    useEffect(() => {
        let filteredDocuments: Document[] = props.documents.filter(document =>
            // (filters.categoryFilter === 'All' || filters.categoryFilter === document.documentCategoryType) &&

            (filters.docNameFilter === 'All' || filters.docNameFilter === document.displayName) &&

            // Refactor possibility
            (filters.planDescFilter === 'All' ||
                (filters.planDescFilter && filters.planDescFilter === (document.userDefinedProperties.filter((properties) => properties['label'] === 'planDescription')).map((property) => property['text'])[0])
                || (filters.planDescFilter === '' && (document.userDefinedProperties.filter((properties) => properties['label'] === 'planDescription')).length === 0)) &&

            (filters.planIdFilter === 'All' || filters.planIdFilter === document.fullPlanId) &&

            (filters.accountIdFilter === 'All' || filters.accountIdFilter === document.accountId) &&

            (filters.dateFilter.date <= new Date(document.documentTimestamp)));


        let sortStateIndex = sortStates.findIndex(e => e !== 'DEFAULT');
        let indexName = ['displayName', 'planDescription', 'name', 'fullPlanId', 'accountId', 'documentTimestamp'][sortStateIndex];

        if (sortStateIndex !== -1) {
            if (sortStates[sortStateIndex] === 'ASC') {
                if (indexName === 'planDescription' || indexName === 'name') {
                    filteredDocuments = [...filteredDocuments]
                        .sort((a, b) => (((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                            <
                            (((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                            ? -1 : 1);
                } else {
                    filteredDocuments = [...filteredDocuments]
                        .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase())
                            <
                            ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1));
                }
            } else { //It's in default state or desc
                if (indexName === 'planDescription' || indexName === 'name') {
                    filteredDocuments = [...filteredDocuments]
                        .sort((a, b) => (((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                            >
                            (((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                            ? -1 : 1);
                } else {
                    filteredDocuments = [...filteredDocuments]
                        .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase())
                            >
                            ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1));
                }
            }
        }

        setNumEntries(filteredDocuments.length);

        setFilteredAndSortedDocState(filteredDocuments);

        filteredDocuments = filteredDocuments.slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), filteredDocuments.length));

        setDocumentsState(filteredDocuments);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, paginationVals, props.documents])



    let UDFs: [];

    const auth = useAuth();

    const handlePdfLinkClick = (pdfLink: string) => {
        const pdfWindow = window.open('loading...');
        fetch(pdfLink, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(async (response) => {
            const file = await response.blob();
            const fileURL = URL.createObjectURL(file);
            if (pdfWindow && file) {
                pdfWindow.location.href = fileURL;
            }
        })
            .catch((error) => {
                alert(error);
            })
    };

    const getSortState = (index: number) => {
        switch (sortStates[index]) {
            case 'DEFAULT':
                return 'triangle-default';
            case 'ASC':
                return 'triangle-asc';
            case 'DESC':
                return 'triangle-desc';
            default:
                return 'triangle-default';
        }
    }

    const handleSortClick = (index: number) => {
        let tempSortStates = ['DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT'];
        let indexName = ['displayName', 'planDescription', 'name', 'fullPlanId', 'accountId', 'documentTimestamp'][index];
        if (sortStates[index] === 'ASC') {
            tempSortStates[index] = 'DESC';
            setSortStates([...tempSortStates]);
            //Special logic needed to parse/sort UDFs
            // Switch to sort on props.documents and uncomment the slice if we want column sort to sort the original documents array instead of just the visible paginated results.
            // Note: Sorting on original doc array clashes with the inherit purpose of the filter dropdowns though. TBD?
            if (indexName === 'planDescription' || indexName === 'name') {
                setDocumentsState([...filteredAndSortedDocState]
                    .sort((a, b) => (((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                        >
                        (((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                        ? -1 : 1)
                    .slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), numEntries)));
            } else {
                setDocumentsState([...filteredAndSortedDocState]
                    .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase())
                        >
                        ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1))
                    .slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), numEntries)));
            }
        } else { //It's in default state or desc    
            tempSortStates[index] = 'ASC';
            setSortStates([...tempSortStates]);
            //Special logic needed to parse/sort UDFs
            if (indexName === 'planDescription' || indexName === 'name') {
                setDocumentsState([...filteredAndSortedDocState]
                    .sort((a, b) => (((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((a as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                        <
                        (((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text']).length > 0 ? ((b as any).userDefinedProperties.filter((properties: any) => properties['label'] === indexName)).map((property: any) => property['text'])[0] : '')
                        ? -1 : 1)
                    .slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), numEntries)));
            } else {
                setDocumentsState([...filteredAndSortedDocState]
                    .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase())
                        <
                        ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1))
                    .slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), numEntries)));
            }
        }
    }

    const clearFilters = () => {
        updateFilters({ type: FilterActions.ACC_ID_CHANGE, filterData: DEFAULT_FILTER_STATES.accountIdFilter });
        updateFilters({ type: FilterActions.DOC_NAME_CHANGE, filterData: DEFAULT_FILTER_STATES.docNameFilter });
        updateFilters({ type: FilterActions.PLAN_DESC_CHANGE, filterData: DEFAULT_FILTER_STATES.planDescFilter });
        updateFilters({ type: FilterActions.PLAN_ID_CHANGE, filterData: DEFAULT_FILTER_STATES.planIdFilter });
        updateFilters({ type: FilterActions.DATE_CHANGE, filterData: DEFAULT_FILTER_STATES.dateFilter });
        setPaginationVals({ itemsPerPage: 10, currPage: 0 });
        setSortStates(['DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT']);
    }

    return (
        
        <div>
            {documentsState.length <= 0 &&
                <div className="pds-alert pds-alert-information" style={{ width: '61.7%' }}>
                    <div className="pds-card-section">
                        <div className="pds-alert-content" style={{ fontWeight: 'bold' }} role="alert">At this time, you do not have viewable documents.</div>
                    </div>
                </div>
            }

            <div className="pds-select-group">
                <div className="filter-label">Filters</div>
                {/* eslint-disable-next-line */}
                <a className="clear-filter-button" data-testid='test-clear-filter' onClick={clearFilters}>Clear all</a>
                {/* <CategoryToggle handler={updateFilters} filterValue={filters.categoryFilter} /> */}
                <div style={{ display: "table", width: "100%", tableLayout: "fixed", borderSpacing: "15px" }}>
                <DocNameDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} displayNames={props.filterLists ? props.filterLists.displayNames : ['All']} filterValue={filters.docNameFilter} />
                    <PlanDescriptionDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} planDescriptions={planDescFilterOptions} filterValue={filters.planDescFilter} />
                    <PlanIdDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} planIds={props.filterLists ? props.filterLists.fullPlanIds : ['All']} filterValue={filters.planIdFilter} />
                    <AccountIdDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} accountIds={props.filterLists? props.filterLists.accountIds: ['All']} filterValue={filters.accountIdFilter} buType={'Insurance'} />
                    <DateDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} filterValue={filters.dateFilter.filter} />
                </div>
            </div>
            
            {documentsState.length > 0 ? (
                <><table className='pds-table pds-table-bordered' id="pds-table-docTable">
                    <thead>
                        <tr>
                            <th className="employer-th">Document<div className={getSortState(0)} onClick={() => handleSortClick(0)}></div></th>
                            <th className="employer-th">Description<div className={getSortState(1)} onClick={() => handleSortClick(1)}></div></th>
                            <th className="employer-th">Name(s)<div className={getSortState(2)} onClick={() => handleSortClick(2)}></div></th>
                            <th className="employer-th">Plan ID<div className={getSortState(3)} onClick={() => handleSortClick(3)}></div></th>
                            <th className="employer-th">Account ID<div className={getSortState(4)} onClick={() => handleSortClick(4)}></div></th>
                            <th className="employer-th">Date<div className={getSortState(5)} onClick={() => handleSortClick(5)}></div></th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentsState.map((document, index) => <tr key={index}>
                            <td>
                                {document.uiDisplayViewed ?
                                    (<a className="pds-link read" onClick={() => handlePdfLinkClick(document.links.pdf)} href="#/">{document.displayName}</a>) :
                                    (
                                        <a className="pds-link unread" style={{ fontWeight: 'bold' }} onClick={() => handlePdfLinkClick(document.links.pdf)} href="#/">{document.displayName}</a>
                                    )}
                            </td>
                            {document.userDefinedProperties.length === 0 ?
                                (<td></td>) :
                                (UDFs = document.userDefinedProperties
                                    .filter((properties) => { return properties['label'] === 'planDescription'; }) as [],
                                    UDFs.length === 0 ?
                                        (<td></td>) :
                                        (UDFs.map((property, index) => <td key={index}>{property['text']}</td>)))}
                            {document.userDefinedProperties.length === 0 ?
                                (<td></td>) :
                                (UDFs = document.userDefinedProperties
                                    .filter((properties) => { return properties['label'] === 'name'; }) as [],
                                    UDFs.length === 0 ?
                                        (<td></td>) :
                                        (UDFs.map((property, index) => <td key={index}>{property['text']}</td>)))}
                            <td>{document.fullPlanId}</td>
                            <td>{document.accountId}</td>
                            <td>{document.documentTimestamp.split('T')[0]}</td>
                        </tr>
                        )}
                    </tbody>
                </table><div style={{ marginLeft: '51%', marginRight: '10.5%' }}>
                        <PaginationDropdown handler={setPaginationVals} itemsPerPage={paginationVals.itemsPerPage} numEntries={numEntries} />
                        <PaginationNavigation handler={setPaginationVals} pagination={paginationVals} numEntries={numEntries} />
                    </div>
                </>) :
                <p style={{ marginLeft: '18.3%' }}>Sorry, no documents matched your criteria. Would you like to try again?&nbsp;
                    {/* eslint-disable-next-line */}
                    <a className="clear-filters" style={{ cursor: 'pointer' }} onClick={clearFilters}>Clear filters</a>
                </p>
            }
            <div className="pds-alert pds-alert-information" style={{ width: '61.7%' }}>
                <div className="pds-card-section">
                    <div className="pds-alert-content" role="alert">This page displays document history from 2023-today. Can't find what you're looking for?
                        Reach out to your Principal Plan Contact for assistance.</div>
                </div>
            </div>
        </div>
    )
}

export default EmployerDocTable;