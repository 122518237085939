type propType = {
    handler: any,
    itemsPerPage: number,
    numEntries: number,
}

const PaginationDropdown = (props: propType) => {

    const change = (selectedOption: string) => {
        props.handler( {itemsPerPage: parseInt(selectedOption), currPage: 0 });
    }

    return (
        <div className="pagination-dropdown">
            Show &nbsp; <div className="pds-select" style={{display: 'inline-flex'}}>
                <select value={props.itemsPerPage} name="select-input" data-testid='pagination-dropdown-select' onChange={(e) => change(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select> 
            </div>&nbsp; of {props.numEntries} entries &nbsp; &nbsp;
        </div>
    )
}
export default PaginationDropdown;