type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    handler: any,
    pagination: paginationObjectType,
    numEntries: number,
}

const PaginationNavigation = (props: propType) => {

    // conditional class name insert to dictate disabling of forward and backwards pagination navigation
    const renderDisable = (direction: string) => {
        if ((props.pagination.currPage === 0 && direction === 'backward') || (props.pagination.currPage === Math.ceil(props.numEntries/props.pagination.itemsPerPage)-1 && direction === 'forward')) {
            return 'disabled';
        }
        return '';
    }

    // need to add css to class name of anchor tag parent to display cursor not-allowed
    const renderNotAllowed = (direction: string) => {
        if ((props.pagination.currPage === 0 && direction === 'backward') || (props.pagination.currPage === Math.ceil(props.numEntries/props.pagination.itemsPerPage)-1 && direction === 'forward')) {
            return 'not-allowed';
        }
        return '';
    }

    const clickNav = (element: HTMLElement | null) => {
        if (element && element!.textContent) {
            props.handler({ itemsPerPage: props.pagination.itemsPerPage, currPage: (parseInt(element!.textContent!) - 1) })
        }
    }

    return (
        <nav className="pds-pagination" style={{ display: 'inline-block' }} aria-label="Table Navigation">
            <ul>
                {/* eslint-disable jsx-a11y/anchor-is-valid*/}
                <li className={`pds-pagination-item ${renderNotAllowed('backward')}`}><a className={`pds-pagination-link pds-pagination-fly-first ${renderDisable('backward')}`} onClick={(e) => { props.handler({ itemsPerPage: props.pagination.itemsPerPage, currPage: 0 }) }} tabIndex={0} aria-label="First page"> </a></li>
                <li className={`pds-pagination-item ${renderNotAllowed('backward')}`}><a className={`pds-pagination-link pds-pagination-step-forward ${renderDisable('backward')}`} onClick={() => { props.handler({ itemsPerPage: props.pagination.itemsPerPage, currPage: props.pagination.currPage - 1 }) }} tabIndex={0} aria-label="Previous page"> </a></li>
                {
                    // Pagination active is at the 1st box
                    props.pagination.currPage === 0 ?
                        (<>
                            <li className="pds-pagination-item"><a className="pds-pagination-link pds-pagination-active" aria-label='Go to page one'>1</a></li>
                            {
                                // need 2 pages aka 2nd pagination box
                                (props.numEntries - props.pagination.itemsPerPage) > 0 ?
                                    (<li className="pds-pagination-item"><a className="pds-pagination-link" id="nav2" 
                                    onClick={() => { clickNav(document.getElementById('nav2')) }} 
                                    aria-label='Go to page two'>2</a></li>) :
                                    (<></>)
                            }
                            {
                                // need 3 pages, aka 3rd pagination box
                                (props.numEntries - (props.pagination.itemsPerPage * 2)) > 0 ?
                                    (<li className="pds-pagination-item"><a className="pds-pagination-link" id="nav3" 
                                    onClick={() => { clickNav(document.getElementById('nav3')) }} 
                                    aria-label='Go to page three'>3</a></li>) :
                                    (<></>)
                            }
                        </>) :
                        (
                            // Pagination active is at the 2nd box props.pagination.currPage+2 <= Math.ceil(props.numEntries/props.pagination.itemsPerPage) &&
                            props.pagination.currPage >= 1 && (props.pagination.currPage === 1 || props.pagination.currPage + 1 !== Math.ceil(props.numEntries / props.pagination.itemsPerPage)) && (props.numEntries - props.pagination.itemsPerPage) > 0 ?
                                (<>
                                    <li className="pds-pagination-item"><a className="pds-pagination-link" id="nav1" 
                                    onClick={() => { clickNav(document.getElementById('nav1')) }} 
                                    aria-label={`Go to page ${props.pagination.currPage}`}>{props.pagination.currPage}</a></li>
                                    <li className="pds-pagination-item"><a className="pds-pagination-link pds-pagination-active" id="nav2" 
                                    onClick={() => { clickNav(document.getElementById('nav2')) }} 
                                    aria-label={`Go to page ${props.pagination.currPage + 1}`}>{props.pagination.currPage + 1}</a></li>

                                    {
                                        // Pagination needs more than 2 pages, add 3rd pagination box else none
                                        (props.pagination.currPage + 1) * props.pagination.itemsPerPage + 1 <= props.numEntries ?
                                            (<li className="pds-pagination-item"><a className="pds-pagination-link" id="nav3" 
                                            onClick={() => { clickNav(document.getElementById('nav3')) }} 
                                            aria-label={`Go to page ${props.pagination.currPage + 2}`}>{props.pagination.currPage + 2}</a></li>) :
                                            (<></>)
                                    }
                                </>) :
                                (<>
                                    <li className="pds-pagination-item"><a className="pds-pagination-link" id="nav1"
                                        onClick={() => { clickNav(document.getElementById('nav1')) }}
                                        aria-label={`Go to page ${props.pagination.currPage}`}>{props.pagination.currPage - 1}</a></li>
                                    <li className="pds-pagination-item"><a className="pds-pagination-link" id="nav2"
                                        onClick={() => { clickNav(document.getElementById('nav2')) }} 
                                        aria-label={`Go to page ${props.pagination.currPage + 1}`}>{props.pagination.currPage}</a></li>
                                    <li className="pds-pagination-item"><a className="pds-pagination-link pds-pagination-active" id="nav3" 
                                    onClick={() => { clickNav(document.getElementById('nav3')) }} 
                                    aria-label={`Go to page ${props.pagination.currPage + 2}`}>{props.pagination.currPage + 1}</a></li>
                                </>)

                        )
                }
                {/* <li className="pds-pagination-item"><a className="pds-pagination-link pds-pagination-active ">{props.pagination.currPage}</a></li> */}
                {/* <li className="pds-pagination-item" id="test2"><a className="pds-pagination-link " onClick={() => { console.log(document.getElementById('test2')?.textContent); setPaginationVals({ itemsPerPage: paginationVals.itemsPerPage, currPage: (parseInt(document.getElementById('test2')?.textContent ? document.getElementById('test2')!.textContent! : '0') - 1) }); }} aria-label="go to page two">2</a></li> */}
                {/* <li className="pds-pagination-item"><a className="pds-pagination-link" aria-label="go to page three">3</a></li> */}
                <li className={`pds-pagination-item ${renderNotAllowed('forward')}`}><a className={`pds-pagination-link pds-pagination-step-backward ${renderDisable('forward')}`}
                    onClick={() => { props.handler({ itemsPerPage: props.pagination.itemsPerPage, currPage: props.pagination.currPage + 1 }) }} tabIndex={0} aria-label="Next page"> </a></li>
                <li className={`pds-pagination-item ${renderNotAllowed('forward')}`}><a className={`pds-pagination-link pds-pagination-fly-last ${renderDisable('forward')}`}
                    onClick={() => { props.handler({ itemsPerPage: props.pagination.itemsPerPage, currPage: Math.ceil(props.numEntries / props.pagination.itemsPerPage) - 1 }) }} aria-label="Last page"> </a></li>
                {/* eslint-enable jsx-a11y/anchor-is-valid*/}
            </ul>
        </nav>

    )
}
export default PaginationNavigation;