import { FunctionComponent, useEffect, useState } from 'react';
import Header from "./Header";
import DocTable from './DocTable';
import AppFooter from './AppFooter';
import { useAuth, withAuth } from 'react-oidc-context';
import jwt from 'jwt-decode';
import Unauthorized from './Unauthorized'

const DOC_URL = "documents/member";
const USERNAME_URL = "username";

type AnnuitiesDocument = {
    accountId: string,
    businessSegment: string,
    displayCategory: string,
    displayName: string,
    documentTimestamp: string,
    uiDisplayViewed: boolean,
    links: {
        pdf: string
    },
}

type FilterLists = {
    displayNames: [],
    displayCategories: [],
    accountIds: [],
}

const Annuities: FunctionComponent<{}> = ({ }) => {


    const [documents, updateDocuments] = useState<AnnuitiesDocument[]>([]);
    const [username, updateUsername] = useState<string>("");
    const [isLoading, updateIsLoading] = useState<boolean>(true);
    const [filterProps, updateFilterProps] = useState<FilterLists>({} as FilterLists);
    const [isMember, updateIsMember] = useState<boolean>(true); //set as true initially, or else render flow will flash unauthorized screen as state updates

    const auth = useAuth();

    useEffect(() => {

        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth]);

    useEffect(() => {
        let tokenData: any = {};

        if (auth.isAuthenticated) {

            if (auth.user?.access_token) {
                tokenData = jwt(auth.user.access_token);
            }

            updateIsMember(tokenData.impersonation === 'Participant' || tokenData.pfgmemberflg === 'Y');

            if (tokenData.impersonation === 'Participant' || tokenData.pfgmemberflg === 'Y') {

                fetch(process.env.REACT_APP_API_URL + USERNAME_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.text())
                    .then(text => updateUsername(text))
                    .catch(error => alert(error.message));

                fetch(process.env.REACT_APP_API_URL + DOC_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.json())
                    .then(json => {
                        updateDocuments(json.documents.annuities.reverse());
                        updateFilterProps(json.filters.annuities);
                        updateIsLoading(false);
                    })
                    .catch(error => alert(error.message));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated]);

    return (
        <div className="annuities-page-parent">
            <Header />
            {isMember ?
                (<div className="annuities-page">
                    <h1>Annuity documents</h1>
                    <div style={{ marginLeft: "18.4%" }}>For {username}</div>
                    {isLoading ?
                        (
                            <div style={{ marginLeft: "18.4%" }}>
                                <div className="pds-util-inline-flex pds-animatedIcon" aria-label="loading">
                                    <i data-feather="loader" aria-hidden="true"></i>
                                </div>
                            </div>
                        ) :
                        (<DocTable documents={documents} filterLists={filterProps} BuType={'Annuities'} />)
                    }
                </div>) :
                <Unauthorized />
            }
            <AppFooter />
        </div>
    )
}

export default withAuth(Annuities);