/* eslint-disable @typescript-eslint/no-explicit-any */
import {HeaderNav} from '@principal-employer-experience/employers-primary-navigation';
import '@principal/design-system/pds-styles.min.css';
import {useAuth} from 'react-oidc-context';

export interface HeaderProps {
  environment: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, func-style
export function EmployerHeader() {

  const auth = useAuth();
  const accessToken: any = auth.user?.access_token;
  const environment: any = process.env.REACT_APP_ENV;
  const enterpriseId: any = auth.user?.profile.pfgenterpriseid;

  return <HeaderNav enterpriseId={enterpriseId} access_token={accessToken} environment={environment} />;
}

