const validRedirectUrls: string[] = [
    'https://doclocker.enterprisedocuments.dev.principal.com/',
    'https://doclocker.enterprisedocuments.staging.principal.com/',
    'https://doclocker.enterprisedocuments.principal.com/'
]

const getSafeUrls = () => {
    if (validRedirectUrls.includes(`${process.env.REACT_APP_REDIRECT_URL}`)) {
        return `${process.env.REACT_APP_REDIRECT_URL}`;
    } else {
        return '/#'
    }
}

const Unauthorized = () => {
    return (
        <div className="pds-unauthenticatedError">
            <div className="pds-unauthenticatedError-background">
                <section className="pds-imgStanTheMan pds-util-padding-top-20 pds-util-padding-bottom-10">
                    <div className="pds-typography-center">
                        <div>
                            <h1 className="pds-typography-h2 pds-util-margin-top-10 pds-util-margin-bottom-0">Pardon me?</h1>
                        </div>
                        <div className="pds-util-padding-horz-10">
                            <p className="pds-util-margin-vert-0">It looks like you don't have permission to view this page. If you think you should have permission, contact your primary administrator.</p>
                            <p className="pds-util-margin-vert-0">
                                <a href={getSafeUrls()}>Head back to "home" page</a>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Unauthorized;