enum FilterActions {
    NAME_CHANGE = 'NAME',
    CAT_CHANGE = 'CATEGORY',
    ACC_ID_CHANGE = 'ACCOUNTID',
    DATE_CHANGE = 'DATE',
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    handler: any,
    pageUpdate: any,
    pagination: paginationObjectType,
    displayCategories: string[],
    buType: string,
}

const CategoryDropdown = (props: propType) => {
    return (
        <div className="filter-dropdown">
            {props.buType === 'Bank' ? (
                <label className="pds-label">Type</label>
            ) :
                (
                    <label className="pds-label">Product</label>
                )
            }
            <div className="pds-select">
                <select name="select-input" onChange={
                    (e) => {
                        props.pageUpdate({ itemsPerPage: props.pagination.itemsPerPage, currPage: 0 });
                        props.handler({ type: FilterActions.CAT_CHANGE, filterData: e.target.value });
                    }
                }>
                    {props.displayCategories.map((displayCategory, index) => <option key={index} value={displayCategory}>{displayCategory}</option>)}
                </select>
            </div>
        </div>
    )
}
export default CategoryDropdown;