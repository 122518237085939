import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Bank from './components/Bank';
import IndividualInsurance from './components/IndividualInsurance';
import Tax from './components/Tax';
import IndividualInsuranceEmployer from './components/IndividualInsuranceEmployer';
import {SPAAuthProvider} from '@principalfinancialgroup/ciam-spa-auth-provider';
import Annuities from './components/Annuities';

// eslint-disable-next-line max-lines-per-function
const App = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID!;
  const redirectUri = process.env.REACT_APP_REDIRECT_URL!;
  const issuer = process.env.REACT_APP_AUTHORITY_URL!;
  const postLogourUri = process.env.REACT_APP_POST_LOGOUT_URL;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <SPAAuthProvider
              clientId={clientId}
              redirectUri={redirectUri}
              issuer={issuer}
              postLogoutUri={postLogourUri}
              scopes={['openid', 'profile', 'api://ebs/employer-experience/header.read']}
              debug={false}
            >
              <Tax />
            </SPAAuthProvider>
          }
        />
        <Route
          path="/annuities"
          element={
            <SPAAuthProvider
              clientId={clientId}
              redirectUri={redirectUri}
              issuer={issuer}
              postLogoutUri={postLogourUri}
              scopes={['openid', 'profile', 'api://ebs/employer-experience/header.read']}
              debug={false}
            >
              <Annuities />
            </SPAAuthProvider>
          }
        />
        <Route
          path="/tax"
          element={
            <SPAAuthProvider
              clientId={clientId}
              redirectUri={redirectUri}
              issuer={issuer}
              postLogoutUri={postLogourUri}
              scopes={['openid', 'profile', 'api://ebs/employer-experience/header.read']}
              debug={false}
            >
              <Tax />
            </SPAAuthProvider>
          }
        />
        <Route
          path="/individual-insurance"
          element={
            <SPAAuthProvider
              clientId={clientId}
              redirectUri={redirectUri}
              issuer={issuer}
              postLogoutUri={postLogourUri}
              scopes={['openid', 'profile', 'api://ebs/employer-experience/header.read']}
              debug={false}
            >
              <IndividualInsurance />
            </SPAAuthProvider>
          }
        />
        <Route
          path="/bank"
          element={
            <SPAAuthProvider
              clientId={clientId}
              redirectUri={redirectUri}
              issuer={issuer}
              postLogoutUri={postLogourUri}
              scopes={['openid', 'profile', 'api://ebs/employer-experience/header.read']}
              debug={false}
            >
              <Bank />
            </SPAAuthProvider>
          }
        />
        <Route
          path="/employer"
          element={
            <SPAAuthProvider
              clientId={clientId}
              redirectUri={redirectUri}
              issuer={issuer}
              postLogoutUri={postLogourUri}
              scopes={['openid', 'profile', 'api://ebs/employer-experience/header.read']}
              debug={false}
            >
              <IndividualInsuranceEmployer />
            </SPAAuthProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
