import { useAuth, withAuth } from 'react-oidc-context';

const validRedirectUrls: string[] = [
  'https://secure05.test.principal.com/',
  'https://secure05.pilot.principal.com/',
  'https://secure05.principal.com/'
]

const safeUrlSuffix: string[] = [
  'member/accounts',
  'allweb/docs/corporate/help.htm',
  'yourprofile/isea/member/yourprofile.jsp',
  'servlet/common/member/messagecenter'
]

const Header = () => {
    
    const auth = useAuth();

    const getSafeUrls = (index: number) => {
      if (validRedirectUrls.includes(`${process.env.REACT_APP_ENV_URL}`)) {
        return `${process.env.REACT_APP_ENV_URL}${safeUrlSuffix[index]}`;
      } else {
        return '/#'
      }
    }

    return (
        <nav className="pds-navigationPrimary pds-navigationPrimary-primary pds-enterpriseHeader" style={{overflow: 'hidden'}}>
        <div className="pds-navigationPrimary-container container">
          <div className="pds-navigationPrimary-logo">
            <a data-gtm="navigation-primary" className="pds-navigationPrimary-logo-link pds-link-unstyled" href={getSafeUrls(0)} aria-label="Principal Homepage">
              <img alt="" className="pds-imgLogoCompanyNameWhiteRetina"/>
            </a>
          </div>
          <div className="pds-navigationPrimary-menu-container">
            <ul className="pds-navigationPrimary-tier-one">
              <li>
                <a data-gtm="navigation-primary" className="pds-link-unstyled" href={getSafeUrls(0)}>My Accounts</a>
              </li>
              <li>
                <a data-gtm="navigation-primary" className="pds-link-unstyled"  href={getSafeUrls(1)}>Help</a>
              </li>
              <li className="pds-navigationPrimary-menu-pull-right">
                <a data-gtm="navigation-primary" className="pds-link-unstyled"  href={getSafeUrls(2)}>My Profile</a>
              </li>
              <li className="pds-navigationPrimary-menu-pull-right">
                <a data-gtm="navigation-primary" className="pds-link-unstyled" href={getSafeUrls(3)}>Messages</a>
              </li>
              <li className="pds-navigationPrimary-menu-pull-right">
              <button className="pds-button pds-button-primary" onClick={() => {auth.removeUser(); auth.revokeTokens(); auth.clearStaleState(); auth.signoutRedirect({id_token_hint: auth.user?.id_token}); }}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
}

export default withAuth(Header);