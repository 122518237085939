enum FilterActions {
    ACC_ID_CHANGE = 'ACCOUNTID',
    CAT_CHANGE = 'CATEGORY',
    DATE_CHANGE = 'DATE',
    DOC_NAME_CHANGE = 'DOCNAME',
    PLAN_DESC_CHANGE = 'PLANDESCRIPTION',
    PLAN_ID_CHANGE = 'PLANID',
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    handler: any,
    pageUpdate: any,
    pagination: paginationObjectType,
    accountIds: string[],
    filterValue: string,
    buType: string,
}

const AccountIdDropdown = (props: propType) => {
    return (
        <div className="filter-dropdown">
            {props.buType === 'Annuities' ? (
                <label className="pds-label">Contract number</label>
            ) :
                (
                    <label className="pds-label">Account ID</label>
                )
            }
            <div className="pds-select">
                <select value={props.filterValue} name="select-input-account" data-testid='test-doc-acnid-filter' onChange=
                    {
                        (e) => {
                            props.pageUpdate({ itemsPerPage: props.pagination.itemsPerPage, currPage: 0 });
                            props.handler({ type: FilterActions.ACC_ID_CHANGE, filterData: e.target.value })
                        }
                    }>
                    {props.accountIds.map((accountId, index) => <option key={index} value={accountId}>{accountId}</option>)}
                </select>
            </div>
        </div>
    )
}
export default AccountIdDropdown;