import { useEffect, useState } from 'react';
import Header from "./Header";
import AppFooter from './AppFooter';
import { withAuth, useAuth } from "react-oidc-context"
import jwt from 'jwt-decode';
import Unauthorized from './Unauthorized';

const DOC_URL = "documents/member";
const USERNAME_URL = "username";
const DEFAULT_TAX_YEAR = new Date().getFullYear().toString();

type TaxYearType = string | null;
type TaxDocument = {
    displayCategory: string,
    displayName: string,
    documentTimestamp: string,
    uiDisplayViewed: boolean,
    links: {
        pdf: string
    },
}

const Tax = () => {
    const [taxYear, updateTaxYear] = useState<TaxYearType>(DEFAULT_TAX_YEAR);
    const [documents, updateDocuments] = useState<TaxDocument[]>([]);
    const [username, updateUsername] = useState<string>("");
    const [isLoading, updateIsLoading] = useState<boolean>(true);
    const [isMember, updateIsMember] = useState<boolean>(true); //set as true initially, or else render flow will flash unauthorized screen as state updates

    let filteredDocuments: TaxDocument[] = documents.filter(document => document.displayCategory === taxYear);

    const auth = useAuth();

    useEffect(() => {

        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth]);

    useEffect(() => {
        // component did mount
        let tokenData: any = {};

        if (auth.isAuthenticated) {

            if (auth.user?.access_token) {
                tokenData = jwt(auth.user.access_token);
            }

            updateIsMember(tokenData.impersonation === 'Participant' || tokenData.pfgmemberflg === 'Y');

            if (tokenData.impersonation === 'Participant' || tokenData.pfgmemberflg === 'Y') {

                fetch(process.env.REACT_APP_API_URL + USERNAME_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.text())
                    .then(text => updateUsername(text))
                    .catch(error => alert(error.message));

                fetch(process.env.REACT_APP_API_URL + DOC_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.json())
                    .then(json => {
                        updateDocuments(json.documents.tax) /*if statement*/;
                        updateIsLoading(false);
                    })
                    .catch(error => alert(error.message));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated]);

    const handlePdfLinkClick = (pdfLink: string) => {
        const pdfWindow = window.open('loading...');
        fetch(pdfLink, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(async (response) => {
            const file = await response.blob();
            const fileURL = URL.createObjectURL(file);
            if (pdfWindow && file) {
                pdfWindow.location.href = fileURL;
            }
        })
            .catch((error) => {
                alert(`There was a problem fetching your documents: ${error}`);
            })
    };

    return (
        <div className="tax-page-parent">
            <Header />
            {isMember ?
                (<div className="tax-page">
                    <h1>Tax Documents</h1>
                    <div style={{ marginLeft: "18.5%" }}>For {username}</div>
                    {isLoading ?
                        (
                            <div style={{ marginLeft: "18.4%" }}>
                                <div className="pds-util-inline-flex pds-animatedIcon" aria-label="loading">
                                    <i data-feather="loader" aria-hidden="true"></i>
                                </div>
                            </div>
                        ) :
                        (
                            <>
                                {/* {THIS IS CANDIDATE FOR COMPONENT} */}
                                <div className='pds-select-group'>
                                    <div style={{ display: "table", width: "100%", tableLayout: "fixed", borderSpacing: "15px", marginTop: "-50px" }}>
                                        <div className="filter-dropdown">
                                            <label className="pds-label" >Tax Year</label>

                                            <div className="pds-select" style={{ width: "100px" }}>
                                                {/* {REASSESS THIS ON CHANGE EVENT EVENT HANDLER TYPESCRIPT} */}

                                                <select name="select-input" data-testid='test-tax-date-filter' onChange={(e) => updateTaxYear(e.target.value)}>
                                                    <option value={new Date().getFullYear().toString()}>{new Date().getFullYear()}</option>
                                                    <option value={(new Date().getFullYear() - 1).toString()}>{new Date().getFullYear() - 1}</option>
                                                    <option value={(new Date().getFullYear() - 2).toString()}>{new Date().getFullYear() - 2}</option>
                                                    <option value={(new Date().getFullYear() - 3).toString()}>{new Date().getFullYear() - 3}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {documents.length > 0 ?
                                    (filteredDocuments.length > 0 ?
                                        (
                                            <table className='pds-table pds-table-bordered' id='pds-table-docTable'>
                                                <thead>
                                                    <tr>
                                                        <th className="doc">Document</th>
                                                        <th>Tax Year</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredDocuments.map((document, index) => <tr key={index}><td>
                                                        {document.uiDisplayViewed ?
                                                            // TODO: Move individual doc links to it's own component?
                                                            (<a className="read" onClick={() => handlePdfLinkClick(document.links.pdf)} href={"/#"}>{document.displayName}</a>) :
                                                            (<a className="pds-link unread" onClick={() => handlePdfLinkClick(document.links.pdf)} href="/#">{document.displayName}</a>)}
                                                    </td><td>{document.displayCategory}</td></tr>)}
                                                </tbody>
                                            </table>
                                        ) :
                                        <div className="pds-alert pds-alert-information" style={{ width: '50%' }}>
                                            <div className="pds-card-section">
                                                <div className="pds-alert-content" role="alert">No Documents found matching the selected filters.</div>
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <div className="pds-alert pds-alert-information" style={{ width: '50%' }}>
                                            <div className="pds-card-section">
                                                <div className="pds-alert-content" role="alert">At this time, you do not have viewable documents.</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )}
                </div>) :
                <Unauthorized />
            }
            <AppFooter />
        </div>
    )
}

export default withAuth(Tax);