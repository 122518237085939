import axios from 'axios';
import { ParamaterRequiredError } from '../errors/parameter-required-error';
import { ParameterType } from '../errors/parameter-type';

export interface apiHeaders {
  enterpriseId: string;
  bffApiKey: string;
  bffApiUrl: string;
}

export async function GetAdmin(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerData: apiHeaders) : Promise<any> {
  
  if (!headerData.enterpriseId ||headerData.enterpriseId.length === 0) {
    throw new ParamaterRequiredError('enterpriseID', ParameterType.Header);
  }
  if (!headerData.bffApiKey || headerData.bffApiKey.length === 0) {
    throw new ParamaterRequiredError('bffApiKey', ParameterType.Header);
  }
  if (!headerData.bffApiUrl || headerData.bffApiUrl.length === 0) {
    throw new ParamaterRequiredError('bffApiUrl', ParameterType.Endpoint);
  }

  const bearerToken = `Bearer ${headerData.bffApiKey}`;
  const apiUrl = `${headerData.bffApiUrl}planData`;

  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: apiUrl,
    headers: { 
      'enterpriseid': headerData.enterpriseId,
      'Authorization': bearerToken
    }
  };
  return (await axios.request(config));
  
}
