enum FilterActions {
    ACC_ID_CHANGE = 'ACCOUNTID',
    CAT_CHANGE = 'CATEGORY',
    DATE_CHANGE = 'DATE',
    DOC_NAME_CHANGE = 'DOCNAME',
    PLAN_DESC_CHANGE = 'PLANDESCRIPTION',
    PLAN_ID_CHANGE = 'PLANID',
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    planIds: string[],
    handler: any,
    pageUpdate: any,
    pagination: paginationObjectType,
    filterValue: string,

}

const PlanIdDropdown = (props: propType) => {
    return (
        <div className="filter-dropdown">
            <label className="pds-label">Plan ID</label>
            <div className="pds-select">
                <select value={props.filterValue} name="select-input-planId" data-testid='test-planId-filter' onChange={
                    (e) => {
                        props.pageUpdate({ itemsPerPage: props.pagination.itemsPerPage, currPage: 0 });
                        props.handler({ type: FilterActions.PLAN_ID_CHANGE, filterData: e.target.value });
                    }
                }>
                    {props.planIds.map((planId, index) => <option key={index} value={planId}>{planId}</option>)}
                </select>
            </div>
        </div>
    )
}
export default PlanIdDropdown;