import { useEffect, useReducer, useState } from "react";
import { useAuth } from "react-oidc-context";
import AccountIdDropdown from "./AccountIdDropdown";
import CategoryDropdown from "./CategoryDropdown";
import DateDropdown from "./DateDropdown";
import DocNameDropdown from "./DocNameDropdown";
import PaginationDropdown from "./PaginationDropdown";
import PaginationNavigation from "./PaginationNavigation";


type Document = {
    accountId: string,
    businessSegment: string,
    displayCategory: string,
    displayName: string,
    documentTimestamp: string,
    uiDisplayViewed: boolean,
    links: {
        pdf: string
    },
}

type FilterLists = {
    displayNames: [],
    displayCategories: [],
    accountIds: [],
}

type DateFilter = {
    date: Date,
    filter: string,
}

const DEFAULT_FILTER = 'All';
const DEFAULT_FILTER_STATES: FilterOptions = {
    nameFilter: DEFAULT_FILTER,
    categoryFilter: DEFAULT_FILTER,
    accountIdFilter: DEFAULT_FILTER,
    dateFilter: { date: new Date(new Date().setFullYear(new Date().getFullYear() - 1)), filter: 'Last year' }
}

enum FilterActions {
    DOC_NAME_CHANGE = 'DOCNAME',
    CAT_CHANGE = 'CATEGORY',
    ACC_ID_CHANGE = 'ACCOUNTID',
    DATE_CHANGE = 'DATE',
}

type FilterOptions = {
    nameFilter: string,
    categoryFilter: string,
    accountIdFilter: string,
    dateFilter: DateFilter,
}

type propType = {
    documents: Document[],
    filterLists: FilterLists,
    BuType: string,
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

const filterReducer = (previous: FilterOptions, action: { type: FilterActions, filterData: any }) => {
    switch (action.type) {
        case FilterActions.DATE_CHANGE:
            return { ...previous, dateFilter: action.filterData };
        case FilterActions.DOC_NAME_CHANGE:
            return { ...previous, nameFilter: action.filterData };
        case FilterActions.CAT_CHANGE:
            return { ...previous, categoryFilter: action.filterData };
        case FilterActions.ACC_ID_CHANGE:
            return { ...previous, accountIdFilter: action.filterData };
        default:
            return previous
    }
};

const DocTable = (props: propType) => {

    props.documents.sort((a, b) => (((a as any)['documentTimestamp'] == null ? "" : (a as any)['documentTimestamp'].toString().toLowerCase())
        > ((b as any)['documentTimestamp'] == null ? "" : (b as any)['documentTimestamp'].toString().toLowerCase()) ? -1 : 1));

    const [filters, updateFilters] = useReducer(filterReducer, DEFAULT_FILTER_STATES);
    const [documentsState, setDocumentsState] = useState<Document[]>(props.documents);
    const [filteredAndSortedDocState, setFilteredAndSortedDocState] = useState<Document[]>(props.documents);
    const [sortStates, setSortStates] = useState<string[]>(['DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT']);
    const [paginationVals, setPaginationVals] = useState<paginationObjectType>({ itemsPerPage: 10, currPage: 0 });
    const [numEntries, setNumEntries] = useState<number>(0);
    const auth = useAuth();

    useEffect(() => {
        let filteredDocuments: Document[] = props.documents.filter(document =>
            (filters.nameFilter === 'All' || filters.nameFilter === document.displayName) &&
            (filters.categoryFilter === 'All' || filters.categoryFilter === document.displayCategory) &&
            (filters.accountIdFilter === 'All' || filters.accountIdFilter === document.accountId) &&
            (filters.dateFilter.date <= new Date(document.documentTimestamp)));

        let sortStateIndex = sortStates.findIndex(e => e !== 'DEFAULT');
        let indexName = ['displayName', 'displayCategory', 'accountId', 'documentTimestamp'][sortStateIndex];

        if (sortStateIndex !== -1) {
            if (sortStates[sortStateIndex] === 'ASC') {
                filteredDocuments = [...filteredDocuments]
                    .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase()) < ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1));
            } else { //It's in default state or desc
                filteredDocuments = [...filteredDocuments]
                    .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase()) > ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1));
            }
        }

        setNumEntries(filteredDocuments.length);

        setFilteredAndSortedDocState(filteredDocuments);

        filteredDocuments = filteredDocuments.slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), filteredDocuments.length));

        setDocumentsState(filteredDocuments);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, paginationVals])

    const handlePdfLinkClick = (pdfLink: string) => {
        const pdfWindow = window.open('loading...');
        fetch(pdfLink, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(async (response) => {
            const file = await response.blob();
            const fileURL = URL.createObjectURL(file);
            if (pdfWindow && file) {
                pdfWindow.location.href = fileURL;
            }
        })
            .catch((error) => {
                alert(error);
            })
    };

    const getTypeOrProduct = (buType: string) => {
        if (buType === 'Bank') {
            return 'Type';
        }
        return 'Product';
    }

    const getAccountOrContract = (buType: string) => {
        if (buType === 'Annuities') {
            return 'Contract number';
        }
        return 'Account';
    }

    const getSortState = (index: number) => {
        switch (sortStates[index]) {
            case 'DEFAULT':
                return 'triangle-default';
            case 'ASC':
                return 'triangle-asc';
            case 'DESC':
                return 'triangle-desc';
            default:
                return 'triangle-default';
        }
    }

    const handleSortClick = (index: number) => {
        let tempSortStates = ['DEFAULT', 'DEFAULT', 'DEFAULT', 'DEFAULT'];
        let indexName = ['displayName', 'displayCategory', 'accountId', 'documentTimestamp'][index];
        if (sortStates[index] === 'ASC') {
            tempSortStates[index] = 'DESC';
            setSortStates([...tempSortStates]);
            // Switch to sort on props.documents and uncomment the slice if we want column sort to sort the original documents array instead of just the visible paginated results.
            // Note: Sorting on original doc array clashes with the inherit purpose of the filter dropdowns though. TBD?
            setDocumentsState([...filteredAndSortedDocState]
                .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase()) > ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1))
                .slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), numEntries)));
        } else { //It's in default state or desc
            tempSortStates[index] = 'ASC';
            setSortStates([...tempSortStates]);
            setDocumentsState([...filteredAndSortedDocState]
                .sort((a, b) => (((a as any)[indexName] == null ? "" : (a as any)[indexName].toString().toLowerCase()) < ((b as any)[indexName] == null ? "" : (b as any)[indexName].toString().toLowerCase()) ? -1 : 1))
                .slice((paginationVals.currPage * paginationVals.itemsPerPage), Math.min((paginationVals.currPage * paginationVals.itemsPerPage + paginationVals.itemsPerPage), numEntries)));
        }
    }

    return (
        <>
            {props.documents.length <= 0 ? (
                <div className="pds-alert pds-alert-information">
                    <div className="pds-card-section">
                        <div className="pds-alert-content" style={{ fontWeight: 'bold' }} role="alert">At this time, you do not have viewable documents.</div>
                    </div>
                </div>
            ) :
                (
                    <>
                        <div className="pds-select-group">
                            <div style={{ display: "table", width: "100%", tableLayout: "fixed", borderSpacing: "15px", marginTop: "-50px", whiteSpace: "initial" }}>
                                <DocNameDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} displayNames={props.filterLists.displayNames} filterValue={filters.nameFilter} />
                                <CategoryDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} displayCategories={props.filterLists.displayCategories} buType={props.BuType} />
                                <AccountIdDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} accountIds={props.filterLists.accountIds} filterValue={filters.accountIdFilter} buType={props.BuType} />
                                <DateDropdown handler={updateFilters} pageUpdate={setPaginationVals} pagination={paginationVals} filterValue={filters.dateFilter.filter} />
                            </div>
                        </div>
                        {documentsState.length > 0 ? (
                            <><table className="pds-table pds-table-bordered" id="pds-table-docTable" aria-live="polite">
                                <thead>
                                    <tr>
                                        <th className="member-th" scope="col">Document<div className={getSortState(0)} onClick={() => handleSortClick(0)}></div></th>
                                        {/* The below label differs between Bank and Life. Bandaid fix implemented till we can one day generalize the BU pages */}
                                        <th className="member-th" scope="col">{getTypeOrProduct(props.BuType)}<div className={getSortState(1)} onClick={() => handleSortClick(1)}></div></th>
                                        <th className="member-th" scope="col">{getAccountOrContract(props.BuType)}<div className={getSortState(2)} onClick={() => handleSortClick(2)}></div></th>
                                        <th className="member-th" scope="col">Date<div className={getSortState(3)} onClick={() => handleSortClick(3)}></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documentsState.map((document, index) => <tr key={index}>
                                        <td>
                                            {document.uiDisplayViewed ?
                                                (<a className="pds-link read" onClick={() => handlePdfLinkClick(document.links.pdf)} href="#/">{document.displayName}</a>) :
                                                (
                                                    <a className="pds-link unread" style={{ fontWeight: 'bold' }} onClick={() => handlePdfLinkClick(document.links.pdf)} href="#/">{document.displayName}</a>
                                                )}
                                        </td>
                                        <td>{document.displayCategory}</td>
                                        <td>{document.accountId}</td>
                                        <td>{document.documentTimestamp.split('T')[0].replace(/(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1")}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                                <div style={{ marginLeft: '51%', marginRight: '10.5%' }}>
                                    <PaginationDropdown handler={setPaginationVals} itemsPerPage={paginationVals.itemsPerPage} numEntries={numEntries} />
                                    <PaginationNavigation handler={setPaginationVals} pagination={paginationVals} numEntries={numEntries} />
                                </div>
                            </>
                        ) :
                            <div className="pds-alert pds-alert-information" style={{ display: 'inline-flex' }}>
                                <div className="pds-card-section">
                                    <div className="pds-alert-content" role="alert">No Documents found matching the selected filters.</div>
                                </div>
                            </div>
                        }
                    </>
                )
            }
        </>
    )
}

export default DocTable;