import { FunctionComponent, useEffect, useState } from 'react';
import {EmployerHeader} from "./HeaderNav";
import AppFooter from './AppFooter';
import { useAuth, withAuth } from 'react-oidc-context';
import EmployerDocTable from './EmployerDocTable';
import jwt from 'jwt-decode';
import Unauthorized from './Unauthorized';

const DOC_URL = "documents/employer";
const USERNAME_URL = "username";

type InsuranceDocument = {
    accountId: string,
    businessSegment: string,
    displayCategory: string,
    displayName: string,
    documentCategoryType: string,
    documentTimestamp: string,
    fullPlanId: string,
    userDefinedProperties: [],
    uiDisplayViewed: boolean,
    links: {
        pdf: string
    },
}

type FilterLists = {
    accountIds: string[],
    displayNames: string[],
    displayCategories: string[],
    fullPlanIds: string[],
}

const IndividualInsuranceEmployer: FunctionComponent<{}> = ({ }) => {


    const [documents, updateDocuments] = useState<InsuranceDocument[]>([]);
    const [username, updateUsername] = useState<string>("");
    const [isLoading, updateIsLoading] = useState<boolean>(true);
    const [filterProps, updateFilterProps] = useState<FilterLists>({} as FilterLists);
    const [isEmployer, updateIsEmployer] = useState<boolean>(true); //set as true initially, or else render flow will flash unauthorized screen as state updates

    const auth = useAuth();

    useEffect(() => {

        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth]);

    useEffect(() => {
        let tokenData: any = {};

        if (auth.isAuthenticated) {


            if (auth.user?.access_token) {
                tokenData = jwt(auth.user.access_token);
            }

            updateIsEmployer(tokenData.impersonation === 'Employer Admin' || tokenData.pfgemployerflg === 'Y');

            if (tokenData.impersonation === 'Employer Admin' || tokenData.pfgemployerflg === 'Y') {

                fetch(process.env.REACT_APP_API_URL + USERNAME_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.text())
                    .then(text => updateUsername(text))
                    .catch(error => alert(error.message));

                fetch(process.env.REACT_APP_API_URL + DOC_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.json())
                    .then(json => {
                        updateDocuments(json.documents.individual);
                        updateFilterProps(json.filters.individual);
                        updateIsLoading(false);
                    })
                    .catch(error => alert(error.message));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated]);

    return (
        <div className="individual-insurance-employer-page-parent">
            <EmployerHeader />
            {isEmployer ?
                (<div className="individual-insurance-employer-page">
                    <h1>My business documents</h1>
                    <p style={{ marginLeft: "18.4%" }}>For {username}</p>
                    {isLoading ?
                        (
                            <div style={{ marginLeft: "18.4%" }}>
                                <div className="pds-util-inline-flex pds-animatedIcon" aria-label="loading">
                                    <i data-feather="loader" aria-hidden="true"></i>
                                </div>
                            </div>
                        ) :
                        (<EmployerDocTable documents={documents} filterLists={filterProps} />)
                    }
                </div>) :
                <Unauthorized />
            }
            <AppFooter />
        </div>
    )
}

export default withAuth(IndividualInsuranceEmployer);