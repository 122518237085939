import { FunctionComponent, useEffect, useState } from 'react';
import Header from "./Header";
import DocTable from './DocTable';
import AppFooter from './AppFooter';
import { useAuth, withAuth } from 'react-oidc-context';
import jwtDecode from 'jwt-decode';
import Unauthorized from './Unauthorized'

const DOC_URL = "documents/member";
const USERNAME_URL = "username";

type BankDocument = {
    accountId: string,
    businessSegment: string,
    displayCategory: string,
    displayName: string,
    documentTimestamp: string,
    uiDisplayViewed: boolean,
    links: {
        pdf: string
    },
}

type FilterLists = {
    displayNames: [],
    displayCategories: [],
    accountIds: [],
}

const validRedirectUrls: string[] = [
    'https://secure05.test.principal.com/',
    'https://secure05.pilot.principal.com/',
    'https://secure05.principal.com/'
]


const Bank: FunctionComponent<{}> = ({ }) => {


    const [documents, updateDocuments] = useState<BankDocument[]>([]);
    const [username, updateUsername] = useState<string>("");
    const [isLoading, updateIsLoading] = useState<boolean>(true);
    const [filterProps, updateFilterProps] = useState<FilterLists>({} as FilterLists);
    const [isMember, updateIsMember] = useState<boolean>(true); //set as true initially, or else render flow will flash unauthorized screen as state updates


    const auth = useAuth();

    useEffect(() => {

        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth]);

    useEffect(() => {
        let tokenData: any = {};

        if (auth.isAuthenticated) {

            if (auth.user?.access_token) { //Look into this falsy statement
                tokenData = jwtDecode(auth.user.access_token);
            }

            updateIsMember(tokenData.impersonation === 'Participant' || tokenData.pfgmemberflg === 'Y');

            if (tokenData.impersonation === 'Participant' || tokenData.pfgmemberflg === 'Y') {

                fetch(process.env.REACT_APP_API_URL + USERNAME_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.text())
                    .then(text => updateUsername(text))
                    .catch(error => alert(error.message)); //Might want to change error.message to a component, how to render though?

                fetch(process.env.REACT_APP_API_URL + DOC_URL, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then(response => response.json())
                    .then(json => {
                        updateDocuments(json.documents.bank);
                        updateFilterProps(json.filters.bank);
                        updateIsLoading(false);
                    })
                    .catch(error => alert(error.message));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated]);

    const getSafeUrls = () => {
        if (validRedirectUrls.includes(`${process.env.REACT_APP_ENV_URL}`)) {
            return `${process.env.REACT_APP_ENV_URL}BankServiceCenter/statements.faces`;
        } else {
            return '/#'
        }
    }

    return (
        <div className="bank-page-parent">
            <Header />
            {isMember ?
                (<div className="bank-page">
                    <h1>Principal Bank Documents</h1>
                    <div style={{ marginLeft: "18.4%" }}>For {username}</div>
                    <p style={{ marginBottom: "0px", marginLeft: "18.4%" }}><strong>Note:</strong> Displaying Principal Bank statements and notifications dated on or after 12/31/2015.</p>
                    <a className="pds-link" style={{ fontWeight: 'bold', marginLeft: '18.4%' }} href={getSafeUrls()}>View statements and notifications dated before 12/31/2015</a>
                    <br />
                    <a className="pds-link" style={{ fontWeight: 'bold', marginLeft: '18.4%' }} href="/tax">View tax documents</a>
                    {isLoading ?
                        (
                            <div style={{ marginLeft: "18.4%" }}>
                                <div className="pds-util-inline-flex pds-animatedIcon" aria-label="loading">
                                    <i data-feather="loader" aria-hidden="true"></i>
                                </div>
                            </div>
                        ) :
                        (<DocTable documents={documents} filterLists={filterProps} BuType={'Bank'} />)
                    }
                </div>) :
                <Unauthorized />
            }
            <AppFooter />
        </div>
    )
}

export default withAuth(Bank);