import { HEADER_PRODUCT_URLS, BFF_API_URLS } from "./constants";

export enum Environment {
    Local = 'local',
    Dev = 'dev',
    Staging = 'staging',
    Prod = 'prod',
  }

export function getURLPerEnv(Env: any): { ManageProducts_URL: string, ManageSecurity_URL: string, BillingApp_URL: string, MyProfile_URL: string, BFF_API_URL: string, MessageCenter_URL: string, LogOut_URL: string } {

    // const isEnvironment = Env === Environment.Prod;

    let BFF_API_URL;
    let ManageProducts_URL;
    let ManageSecurity_URL;
    let BillingApp_URL;
    let MyProfile_URL;
    let MessageCenter_URL;
    let LogOut_URL;


    if (Environment.Dev === Env || Environment.Local === Env || "test" === Env) {
        BFF_API_URL = BFF_API_URLS.BFF_API_URL_DEV;
        ManageProducts_URL = HEADER_PRODUCT_URLS.ManageProducts_URL_TEST;
        ManageSecurity_URL = HEADER_PRODUCT_URLS.ManageSecurity_URL_TEST;
        BillingApp_URL = HEADER_PRODUCT_URLS.BillingApp_URL_TEST;
        MyProfile_URL = HEADER_PRODUCT_URLS.MyProfile_URL_TEST;
        MessageCenter_URL = HEADER_PRODUCT_URLS.MessageCenter_URL_TEST;
        LogOut_URL = HEADER_PRODUCT_URLS.LogOut_URL_TEST;

    }
    else if (Environment.Staging === Env || "pilot" === Env) {
        BFF_API_URL = BFF_API_URLS.BFF_API_URL_STAGING;
        ManageProducts_URL = HEADER_PRODUCT_URLS.ManageProducts_URL_PILOT;
        ManageSecurity_URL = HEADER_PRODUCT_URLS.ManageSecurity_URL_PILOT;
        BillingApp_URL = HEADER_PRODUCT_URLS.BillingApp_URL_PILOT;
        MyProfile_URL = HEADER_PRODUCT_URLS.MyProfile_URL_PILOT;
        MessageCenter_URL = HEADER_PRODUCT_URLS.MessageCenter_URL_PILOT;
        LogOut_URL = HEADER_PRODUCT_URLS.LogOut_URL_PILOT;
    }
    else {
        BFF_API_URL = BFF_API_URLS.BFF_API_URL_PROD;
        ManageProducts_URL = HEADER_PRODUCT_URLS.ManageProducts_URL_PROD;
        ManageSecurity_URL = HEADER_PRODUCT_URLS.ManageSecurity_URL_PROD;
        BillingApp_URL = HEADER_PRODUCT_URLS.BillingApp_URL_PROD;
        MyProfile_URL = HEADER_PRODUCT_URLS.MyProfile_URL_PROD;
        MessageCenter_URL = HEADER_PRODUCT_URLS.MessageCenter_URL_PROD;
        LogOut_URL = HEADER_PRODUCT_URLS.LogOut_URL_PROD;
    }

    return {
        ManageProducts_URL,
        ManageSecurity_URL,
        BillingApp_URL,
        MyProfile_URL,
        BFF_API_URL,
        MessageCenter_URL,
        LogOut_URL
    }
}
