enum FilterActions {
    ACC_ID_CHANGE = 'ACCOUNTID',
    CAT_CHANGE = 'CATEGORY',
    DATE_CHANGE = 'DATE',
    DOC_NAME_CHANGE = 'DOCNAME',
    PLAN_DESC_CHANGE = 'PLANDESCRIPTION',
    PLAN_ID_CHANGE = 'PLANID',
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    planDescriptions: string[],
    handler: any,
    pageUpdate: any,
    pagination: paginationObjectType,
    filterValue: string,
}

const PlanDescriptionDropdown = (props: propType) => {
    return (
        <div className="filter-dropdown">
            <label className="pds-label">Description</label>
            <div className="pds-select">
                <select value={props.filterValue} name="select-input-planDesc" data-testid='test-planDesc-filter' onChange={
                    (e) => {
                        props.pageUpdate({ itemsPerPage: props.pagination.itemsPerPage, currPage: 0 });
                        props.handler({ type: FilterActions.PLAN_DESC_CHANGE, filterData: e.target.value });
                    }
                }>
                    {props.planDescriptions.map((planDescription, index) => <option key={index} value={planDescription}>{planDescription}</option>)}
                </select>
            </div>
        </div>
    )
}
export default PlanDescriptionDropdown;