import {ParameterType} from './parameter-type';

export class ParamaterRequiredError extends Error {
  readonly statusCode: number;

  readonly exposeMessage: boolean;

  /**
   * This error should be thrown when a required path or query parameter isn't passed.
   */
  constructor(parameterName: string, parameterType: ParameterType) {
    super(`'${parameterName}' is a required ${parameterType} parameter`);
    this.name = 'ParameterRequiredError';
    this.statusCode = 400;
    this.exposeMessage = true;
  }
}
