import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import {
  PdsPrimaryNavigation,
  PdsPrimaryNavigationMainMenu,
  PdsPrimaryNavigationMainMenuItem,
  PdsPrimaryNavigationUtilityMenu,
  PdsPrimaryNavigationUtilityMenuItem
} from '@principal/design-system-react';
import { apiHeaders, GetAdmin } from '../service/getAdminData';
import { getURLPerEnv } from '../utils/config';
import { GetMessageCount } from '../service/getMessageData';
import { GetBillingEligibility } from '../service/getBillingEligibilityData';

export interface HeaderNavProps {
  enterpriseId: string;
  access_token: string;
  environment: any;
}
export const HeaderNav = (props: HeaderNavProps): ReactElement => {

  const {
    ManageProducts_URL,
    ManageSecurity_URL,
    BillingApp_URL,
    MyProfile_URL,
    BFF_API_URL,
    MessageCenter_URL,
    LogOut_URL
  } = getURLPerEnv(props.environment);

  const [isPrimary, setAdminData] = useState(false);
  const [messageCount, setMessageData] = useState(0);
  const [isBillingEligible, setBillingElibility] = useState(false);

  const headerData: apiHeaders = {
    enterpriseId: props.enterpriseId,
    bffApiKey: props.access_token,
    bffApiUrl: BFF_API_URL,
  }

  useEffect(() => {
    fetchAdmin();
    fetchMessage();
    fetchBillingEligibility();
  }, [props.enterpriseId, props.access_token, BFF_API_URL]);

  const fetchAdmin = async () => {
    if ((headerData.enterpriseId !== undefined) && (headerData.bffApiKey !== undefined)) {
      await GetAdmin(headerData).then(AdminData => {
        setAdminData(AdminData.data.isPrimary);
      }).catch(error => {
        console.error('error :', error)
      });
    }
  };
  const fetchMessage = async () => {
    if ((headerData.enterpriseId !== undefined) && (headerData.bffApiKey !== undefined)) {
      await GetMessageCount(headerData).then(MessageData => {
        setMessageData(MessageData.data.messageCount);
      }).catch(error => {
        console.error('error :', error)
      });
    }
  };
  const fetchBillingEligibility = async () => {
    if ((headerData.enterpriseId !== undefined) && (headerData.bffApiKey !== undefined)) {
      await GetBillingEligibility(headerData).then(BillingEligibility => {
        setBillingElibility(BillingEligibility.data.isEligible);
      }).catch(error => {
        console.error('error :', error)
      });
    }
  }
  return (
    <div className="header">
      <PdsPrimaryNavigation
        includeAction="notification"
        hideOtherAlerts={true}
        layoutContainerRemovePadding="md"
        layoutContainerVariant="default"
        loginLink="logout"
        loginLinkHref={LogOut_URL}
        loginLinkVariant="primary-inverted"
        messagesCount={messageCount}
        messagesHref={MessageCenter_URL}
        skipToContentHref="#main"
        logoHref={ManageProducts_URL}
        variant="inverted"
      >
        <PdsPrimaryNavigationMainMenu
          aria-label="Main navigation area"
          slot="primary-navigation-main-menu"
        >
          <PdsPrimaryNavigationMainMenuItem
            text="Manage products"
            href={ManageProducts_URL}
          >
          </PdsPrimaryNavigationMainMenuItem>
          {
            isPrimary ? <PdsPrimaryNavigationMainMenuItem
              text="Manage security"
              href={ManageSecurity_URL}
            >
            </PdsPrimaryNavigationMainMenuItem> : null
          }
          {
            isBillingEligible ? <PdsPrimaryNavigationMainMenuItem
              text="Manage bill"
              href={BillingApp_URL}
            >
            </PdsPrimaryNavigationMainMenuItem> : null
          }
        </PdsPrimaryNavigationMainMenu>
        <PdsPrimaryNavigationUtilityMenu slot="primary-navigation-utility-menu">
          <PdsPrimaryNavigationUtilityMenuItem
            href={MyProfile_URL}
          >
            My profile
          </PdsPrimaryNavigationUtilityMenuItem>
        </PdsPrimaryNavigationUtilityMenu>
      </PdsPrimaryNavigation>
    </div>
  );
};
