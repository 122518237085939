enum FilterActions {
    ACC_ID_CHANGE = 'ACCOUNTID',
    CAT_CHANGE = 'CATEGORY',
    DATE_CHANGE = 'DATE',
    DOC_NAME_CHANGE = 'DOCNAME',
    PLAN_DESC_CHANGE = 'PLANDESCRIPTION',
    PLAN_ID_CHANGE = 'PLANID',
}

type paginationObjectType = {
    itemsPerPage: number,
    currPage: number,
}

type propType = {
    handler: any,
    pageUpdate: any,
    pagination: paginationObjectType,
    displayNames: string[],
    filterValue: string,
}

const DocNameDropdown = (props: propType) => {
    return (
        <div className="filter-dropdown">
            <label className="pds-label">Document type</label>
            <div className="pds-select">
                <select value={props.filterValue} name="select-input" data-testid='test-doc-name-filter' onChange={
                    (e) => {
                        props.pageUpdate({ itemsPerPage: props.pagination.itemsPerPage, currPage: 0 });
                        props.handler({ type: FilterActions.DOC_NAME_CHANGE, filterData: e.target.value });
                    }
                    }>
                    {props.displayNames.map((displayName, index) => <option key={index} value={displayName}>{displayName}</option>)}
                </select>
            </div>
        </div>
    )
}
export default DocNameDropdown;